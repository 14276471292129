import request from '@/util/request'

// 获取销售统计折线图
export function getSaleStatisticApi(data) {
    return request({
        url: '/cus/order_statis',
        method: 'get',
        params: data
    })
}

// 个人中心
export function getPersonalMessageApi(data) {
    return request({
        url: '/cus/userCenter',
        method: 'get',
        params: data
    })
}

// 修改密码
export function changePasswordApi(data) {
    return request({
        url: '/biz/user/updatePwd',
        method: 'put',
        params: data,
    })
}

// 推荐人列表
export function getReferrerApi(data) {
    return request({
        url: '/official/referrer',
        method: 'get',
        params: data,
    })
}

// 我的产品
export function getMyProductApi(data) {
    return request({
        url: `/cus/property_page?pageNum=${data.pageNum}&pageSize=${data.pageSize}`,
        method: 'post',
        data,
    })
}

// 推荐列表
export function getMyReferralApi(data) {
    return request({
        url: `/biz/user/my_referral?pageNum=${data.pageNum}&pageSize=${data.pageSize}`,
        method: 'post',
        data,
    })
}

// 开通钱包
export function openWalletApi(data) {
    return request({
        url: '/cus/open_wallet',
        method: 'post',
        data,
    })
}

// 修改交易密码
export function updateTranPasswdApi(data) {
    return request({
        url: '/cus/updateTransPwd',
        method: 'post',
        data,
    })
}

// 获取转让列表
export function transferListApi(data) {
    return request({
        url: '/official/home_page/hosting_transfer_topList/' + data,
        method: 'get',
    })
}

// 获取采购列表
export function purchaseListApi(data) {
    return request({
        url: '/official/home_page/hosting_purchase_topList/' + data,
        method: 'get',
    })
}

// 获取详情页面-成交记录
export function successRecordApi(spuId, data) {
    return request({
        url: '/official/home_page/productTranRecord/' + spuId,
        method: 'get',
        params: data
    })
}

// 历史价格
export function historicalPriceApi(spuId, data) {
    return request({
        url: '/official/home_page/historical_price/' + spuId,
        method: 'get',
        params: data
    })
}

// 产品价格走势
export function spuPriceTrendApi(spuId, data) {
    return request({
        url: '/official/home_page/spu_price_trend/' + spuId,
        method: 'get',
        params: data
    })
}

// 充值
export function rechargeApi(data) {
    return request({
        url: '/cus/recharge',
        method: 'post',
        data
    })
}

// 提现
export function withdrawalApi(data) {
    return request({
        url: '/cus/withdraw',
        method: 'post',
        data
    })
}

// 个人中心-订单列表
export function orderListApi(data) {
    return request({
        url: `/cus/hosting/info_page?pageNum=${data.pageNum}&pageSize=${data.pageSize}`,
        method: 'post',
        data
    })
}

// 成交记录
export function transactionRecordApi(data) {
    return request({
        url: `/cus/transaction/info_page?pageNum=${data.pageNum}&pageSize=${data.pageSize}`,
        method: 'post',
        data
    })
}

// 发起转让
export function startTransApi(data) {
    return request({
        url: '/cus/transfer',
        method: 'post',
        data
    })
}

// 资产可用数量查询
export function canTransNumApi(data) {
    return request({
        url: '/cus/get_ass_inventory/' + data,
        method: 'get',
    })
}

// 委托单详情列表
export function hostingDetailListApi(data) {
    return request({
        url: `/cus/hosting_detail_list?pageNum=${data.pageNum}&pageSize=${data.pageSize}`,
        method: 'post',
        data
    })
}
// 采购交易
export function purchaseDealApi(data) {
    return request({
        url: `/cus/purchase_deal`,
        method: 'post',
        data
    })
}

// 订单撤销
export function revocationApi(data) {
    return request({
        url: `/cus/hosting/revocation`,
        method: 'post',
        data
    })
}

// 资金流水
export function capitalFlowListApi(data) {
    return request({
        url: `/cus/capital_flow/info_page?pageNum=${data.pageNum}&pageSize=${data.pageSize}`,
        method: 'post',
        data
    })
}

// 发起采购
export function purchaseApi(data) {
    return request({
        url: `/cus/purchase`,
        method: 'post',
        data
    })
}

// 转让交易
export function transferDealApi(data) {
    return request({
        url: `/cus/transfer_deal`,
        method: 'post',
        data
    })
}

// 获取手续费
export function availableBalanceApi(data) {
    return request({
        url: `/cus/available_balance`,
        method: 'get',
        data
    })
}

// 注册个人钱包
export function registerPersonalWalletApi(data) {
    return request({
        url: `/cus/register_personal_wallet`,
        method: 'post',
        data
    })
}

// 查询钱包详情
export function walletInfoApi(data) {
    return request({
        url: `/cus/wallet_info`,
        method: 'get',
        params: data
    })
}

// 银行卡列表
export function bindCardsListApi(data) {
    return request({
        url: `/cus/bind_cards`,
        method: 'get',
        params: data
    })
}

// 个人绑定银行卡
export function bindPersonalCardApi(data) {
    return request({
        url: `/cus/bind_personal_card`,
        method: 'post',
        data
    })
}

// 确认绑定个人银行卡-填写验证码
export function confirmBindCardApi(data) {
    return request({
        url: `/cus/confirm_bind_card`,
        method: 'post',
        data
    })
}

// 发起采购下单
export function startPurchaseDealApi(data) {
    return request({
        url: `/cus/purchase_deal`,
        method: 'post',
        data
    })
}

// 发起支付
export function startPurchasePayApi(data) {
    return request({
        url: `/cus/purchase_pay`,
        method: 'post',
        data
    })
}

// 充值下单
export function rechargeOrderApi(data) {
    return request({
        url: `/bus/umfWallet/rechargeOrder`,
        method: 'post',
        data
    })
}

// 充值申请
export function bankCardInitiatePaymentApi(data) {
    return request({
        url: `/bus/umfWallet/payment/bankCardInitiatePayment`,
        method: 'post',
        data
    })
}

// 确认充值
export function bankCardConfirmPaymentApi(data) {
    return request({
        url: `/bus/umfWallet/payment/bankCardConfirmPayment`,
        method: 'post',
        data
    })
}

// 扫码充值申请
export function scanCodeInitiatePaymentApi(data) {
    return request({
        url: `/bus/umfWallet/payment/scanCodeInitiatePayment`,
        method: 'post',
        data
    })
}

// 轮询查询订单状态
export function getByOrderIdApi(data) {
    return request({
        url: `/bus/umfTopUpOrder/getByOrderId`,
        method: 'get',
        params: data
    })
}

// 提现操作
export function balanceWithdrawalApi(data) {
    return request({
        url: `/bus/umfWallet/balanceWithdrawal`,
        method: 'post',
        data
    })
}

// 企业商户注册
export function registerCompanyWalletApi(data) {
    return request({
        url: `/cus/register_company_wallet`,
        method: 'post',
        data
    })
}

// 提交资质
export function uploadCompanyMaterialsApi(data) {
    return request({
        url: `/cus/upload_company_materials`,
        method: 'post',
        data
    })
}


// 对公账户绑定
export function bindCorporateAccountApi(data) {
    return request({
        url: `/cus/bind_corporate_account`,
        method: 'post',
        data
    })
}

// 查询字典
export function dictQueryApi(data) {
    return request({
        url: `/system/dict/data/type/${data}`,
        method: 'get',
    })
}

// 解绑银行卡
export function unboundBindApi(data) {
    return request({
        url: `/cus/unbound_bind/${data}`,
        method: 'post',
    })
}

// 采购下单关闭订单接口
export function purchaseCloseOrderApi(data) {
    return request({
        url: `/cus/purchase_close_order/${data}`,
        method: 'post',
    })
}

// 查询可提现金额
export function getCanWithdrawAmountApi() {
    return request({
        url: `/cus/getCanWithdrawAmount`,
        method: 'get',
    })
}

// 修改昵称
export function editInfoApi(data) {
    return request({
        url: `/cus/edit_info`,
        method: 'post',
        data
    })
}

// 获取支持快捷支付的银行卡
export function supportedFastPayBanksApi(data) {
    return request({
        url: `/sys_bank/supported_fast_pay_banks`,
        method: 'get',
        params: data
    })
}

// 团队成员
export function myTeamApi(data) {
    return request({
        url: `/cus/myTeam?pageNum=${data.pageNum}&pageSize=${data.pageSize}`,
        method: 'post',
        data
    })
}

// 团队成交列表
export function myTeamTransactionApi(data) {
    return request({
        url: `/cus/myTeam_transaction?pageNum=${data.pageNum}&pageSize=${data.pageSize}`,
        method: 'post',
        data
    })
}

// 获取交易时间
export function dealTimeApi(data) {
    return request({
        url: `/official/deal_time`,
        method: 'get',
        params: data
    })
}