import axios from 'axios'
import { Message } from 'element-ui'
import router from '@/router'
import store from "@/store";
import NProgress from 'nprogress' // 引入nprogress插件
import 'nprogress/nprogress.css'
const defaultSettings = require('../../src/settings.js')
// create an axios instance
var jkl = '';
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // api url = base url + request url
  timeout: 10000 //  请求超时
})
// request拦截器
service.interceptors.request.use(
  config => {
    NProgress.start()
    jkl = config.url
    if (window.localStorage.getItem('token')) {
      // 请求时携带token

      config.headers.Authorization = 'Bearer ' + window.localStorage.getItem('token')
    } else {
      config.headers.Authorization = '';
    }
    return config
  },
  error => {
    // 处理请求错误
    return Promise.reject(error)
  }
)
// response拦截器
service.interceptors.response.use(
  response => {
    NProgress.done()
    const res = response.data
    /**
     * code为非0是抛错 可结合自己业务进行修改
     */
    if (response.config.url == '/cus/check_token') {
      // localStorage.removeItem('token');
      // localStorage.removeItem('userInfo');
      return res
    }
    if (res.code === 401) {
      // 此时 token 失效，当清除权限信息
      store.commit("SET_PREISSIONS", []);
      store.commit('SETISLOGIN', false);
      store.commit('SETUSERSTATUS', null);
      store.commit('SETREFRESHFLAG', null);
      localStorage.removeItem('token');
      localStorage.removeItem('userInfo');
      localStorage.removeItem("dealTime");
      Message({
        message: '登录失效，请重新登录',
        type: 'error',
        duration: 3 * 1000
      })
      router.replace({ path: '/' })
    } else if (res.code !== 200) {
      Message({
        message: res.msg,
        type: 'error',
        duration: 3 * 1000
      })
      return Promise.reject('error')
    } else {
      return res
    }
  },
  error => {
    Message({
      message: error,
      type: 'error',
      duration: 3 * 1000
    })
    return Promise.reject(error)
  })

export default service
