import request from './request'
// 公告menu
export const ReportPage = (type) => request.get('/official/home_page/notice/top/' + type)
// 公告详情
export const ReportDetail = (id) => request.get(`/official/home_page/notice/detail/${id}`)
// 商品列表menu
export const ProductList = (data) => request.post(`/official/home_page/spu_centre_page?pageNum=${data.pageNum}&pageSize=${data.pageSize}`, data)
// 实时详情
export const NowQuestion = (params) => request.get('/official/home_page/realTimeQuote', { params })
//注册
export const Register = (data) => request.post('/official/register', data)
// 图片验证码
export const imgCode = () => request.get('/captchaImage')
// 登录
export const LoginBtn = (data) => request.post('/official/login', data)
// y用户信息
export const userInfo = () => request.get('/cus/get_info')
// 公告列表
export const reportList = (data) => request.post(`/official/home_page/notice/info_page?pageNum=${data.pageNum}&pageSize=${data.pageSize}`, data)
// 轮播图
export const zouMaDeng = () => request.get('/official/home_page/banner');
// 产品详情
export const prdDetail = (id) => request.get(`/official/home_page/getDetail/${id}`)
// 成交记录
export const TransactionRecords = () => request.get('/official/home_page/productTranRecord')
// 走势
export const actionTrend = (id) => request.post(`/official/home_page/productTranTrend/${id}`)
// 重制密码
export const remakePassword = (data) => request.post('/official/reset_pwd', data)
//退出登录
export const rebackLogin = (data) => request.post('/logout', data);
// 检查权限
export const checkPermission = () => request.get('/cus/check_token');
// 手机验证码
export const phoneCode = (data) => request.get(`/sendCode/${data.phone}/${data.busType}`)
//立即支付
export const goPay = (data) => request.post('/biz/assetsDetail/buyNow', data);