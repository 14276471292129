import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store'
import ElementUI from 'element-ui';
import * as echarts from "echarts";
import 'element-ui/lib/theme-chalk/index.css';
import VueRouter from 'vue-router'
import './permission';
import "./icons"; // icon
import "@/styles/common.scss"; // global css
Vue.use(ElementUI);
Vue.prototype.$echarts = echarts;
Vue.prototype.$store = store;
Vue.config.productionTip = false;
Vue.use(VueRouter)
import { userInfo } from "@/util"
import { dealTimeApi } from "@/api"

router.beforeEach((to, from, next) => {
  if (from.path == '/') {
    let flag = localStorage.getItem('token') ? true : false
    // let status = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).status : null
    store.commit('SETISLOGIN', flag)
    // store.commit('SETUSERSTATUS', status)

    //如果token存在 重新获取用户信息
    if (flag && !store.state.userInfoForRefresh) {
      userInfo()
        .then((res) => {
          localStorage.setItem("userInfo", JSON.stringify(res.data.user));
          store.commit("SETUSERSTATUS", res.data.user.status);
          store.commit("SETREFRESHFLAG", res.data.user);
        })
        .catch((err) => {
          store.commit("SETUSERSTATUS", null);
          store.commit("SETREFRESHFLAG", null);
        });
      dealTimeApi()
        .then((res) => {
          if (res.data) {
            localStorage.setItem("dealTime", JSON.stringify(res.data));
          } else {
            localStorage.removeItem("dealTime");
          }
        })
        .catch((err) => {
          localStorage.removeItem("dealTime");
        });
    }
  }
  next()
})

router.afterEach(() => {
  window.scrollTo(0, 0);
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
