import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    index: 1,
    productName: '',
    isLogin: false,
    primaryColor: '#C13F3F',
    userStatus: null,
    userInfoForRefresh: null, //只作为刷新的标识 无其他实际意义
  },
  mutations: {
    changeIndex(state, e) {
      state.index++;
    },
    SETPRODUCTNAME(state, e) {
      state.productName = e
    },
    SETISLOGIN(state, e) {
      state.isLogin = e
    },
    SETUSERSTATUS(state, e) {
      state.userStatus = e
    },
    SETREFRESHFLAG(state, e) {
      state.userInfoForRefresh = e
    }
  },
  actions: {
  },
  modules: {
  }
})
